<template>
  <b-overlay
    :show="$store.state.app.isContentLoading"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >

    <b-card
      title="Client Bulk Import"
    >

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <b-row>
            <b-col
              md="12"
              class="pb-1 border-bottom"
            >
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-primary"
                size="sm"
                @click="download"
              >
                <feather-icon
                  icon="FileTextIcon"
                  class="mr-50"
                />
                <span class="align-middle">Download Template</span>
              </b-button>
              <br>
              Open csv file with excel and fill data, then Upload and Save.
            </b-col>
            <hr>
            <!-- Field: CSV File -->
            <b-col
              md="12"
              class="mt-1"
            >
              <b-form-group
                label="Upload File (.csv)"
                label-for="name"
              >
                <b-form-file
                  v-model="fileUploaded"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  accept=".csv"
                />
              </b-form-group>
            </b-col>

          </b-row>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <feather-icon icon="SaveIcon" />&nbsp;
              {{ $t('Save') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="cancel"
            >
              <feather-icon icon="XIcon" />&nbsp;
              {{ $t('Cancel') }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </b-card>
  </b-overlay>
</template>

<script>

import {
  BOverlay, BRow, BCol, BCard, BForm, BFormGroup, BButton, BFormFile,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import { required, integer, regex } from '@validations'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import router from '@/router/index'
import clientStoreModule from '../clientStoreModule'

export default {
  components: {
    BOverlay,
    BCard,
    BForm,
    BFormGroup,
    BButton,
    BRow,
    BCol,
    BFormFile,

    // Form Validation
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {

  },
  data() {
    return {
      required,
      integer,
      regex,
      branchOptions: [],
      staffOptions: [],
    }
  },
  methods: {
    cancel() {
      this.$router.push({ name: 'client-list' })
    },
    download() {
      const rows = [
        ['name', 'phone', 'address', 'credit_balance', 'branch_id', 'staff_id'],
      ]

      const csvContent = `data:text/csv;charset=utf-8,${
        rows.map(e => e.join(',')).join('\n')}`

      const encodedUri = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', 'clients.csv')
      document.body.appendChild(link) // Required for FF

      link.click()
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'client'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, clientStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const fileUploaded = ref(null)

    const onSubmit = () => {
      const formData = new FormData()
      formData.append('file', fileUploaded.value)
      store.dispatch('client/addBulk', formData)
        .then(response => {
          if (response.status === 201) {
            router.push({ name: 'client-list' })
          } else {
            console.log(response)
          }
        })
        .catch(error => {
          window.swal('Error', JSON.stringify(error.response.data).replace(/"([^"]+)":/g, '$1:'))
        })
    }

    return {
      fileUploaded,
      onSubmit,
    }
  },
}
</script>
